@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 10, 10, 10;
  --background-rgb: 255, 255, 255;
}

/* Support dark mode

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 250, 250, 250;
    --background-rgb: 20, 20, 20;
  }
} */

html,
body {
  min-height: 100dvh;
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}
